import React, { FC } from 'react'
import { Seo } from '../components/shared/seo'
import { PageProps } from 'gatsby'
import { Blog } from '../components/blog/blog'
import { useI18n } from '../i18n/i18n-context'
import { Content } from '../components/shared/content'

const translations: TranslationBundles = {
  'en-US': {
    namespace: 'blog',
    translations: {
      seo: {
        title: 'Blog',
        pathname: '/blog',
        description: "Jonathan's developer blog",
      },
      recent_posts: 'Recently published',
      read_more: 'Read more',
      post_to_read: '{{minutes}} min read',
      post_updated: '(updated {{date}})',
    },
  },
}

const BlogPage: FC<PageProps> = () => {
  const { t } = useI18n(translations)

  return (
    <>
      <Seo
        title={t('blog:seo.title')}
        pathname={t('blog:seo.pathname')}
        description={t('blog:seo.description')}
      />
      <Content>
        <Blog />
      </Content>
    </>
  )
}

export default BlogPage
