import React, { FC } from 'react'
import { Flex } from 'baselift'
import { useBreakpoints } from '../../context/breakpoints-context'
import { useTheme } from '../../theme/use-theme'

// This component wraps the content, such as paragraphs, images, etc...
// Does not include the heading of blog posts or home page

export const Content: FC = props => {
  const theme = useTheme()
  const { bp } = useBreakpoints()

  return (
    <Flex
      css={bp({
        flexDirection: 'column',
        alignItems: 'center',
        width: [`calc(100% - ${theme.space('s')} * 2)`, `calc(100% - ${theme.space('m')} * 2)`],
        maxWidth: theme.size(16),
        margin: [theme.space('m', 's', 'l'), theme.space('l', 'm', 'xl')],
      })}
      {...props}
    />
  )
}
