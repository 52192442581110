import React, { FC } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { Text, Stack, Flex } from 'baselift'
import { useI18n, TranslationBundles } from '../../i18n/i18n-context'
import { useTheme } from '../../theme/use-theme'

const translations: TranslationBundles = {
  'en-US': {
    namespace: 'blog',
    translations: {
      seo: {
        title: 'Blog',
        pathname: '/blog',
        description: "Jonathan's developer blog",
      },
      recent_posts: 'Recently published',
      read_more: 'Read more',
      post_to_read: '{{minutes}} min read',
      post_updated: '(updated {{date}})',
    },
  },
}

export const Blog: FC = () => {
  const theme = useTheme()
  const { t } = useI18n(translations)

  const posts = useStaticQuery<any>(
    graphql`
      query AllContentfulJmBlogPosts {
        # TODO: sort by original publish date
        allContentfulJmBlogPost {
          nodes {
            slug
            title
            subtitle
          }
        }
      }
    `
  ).allContentfulJmBlogPost.nodes

  return (
    <>
      <Flex
        css={{
          maxWidth: theme.size(12),
          width: '100%',
        }}
      >
        <Text
          css={{
            fontSize: theme.fontSizes[4],
            color: 'var(--blog-section-heading-color)',
            marginBottom: theme.space('s'),
          }}
        >
          {t('blog:recent_posts')}
        </Text>
      </Flex>
      <Stack
        direction="column"
        space={theme.space('m')}
        css={{
          maxWidth: theme.size(12),
        }}
      >
        {posts.map((p, i) => (
          <BlogPostCard
            key={p.slug ?? i}
            slug={p.slug ?? ''}
            title={p.title ?? ''}
            subtitle={p.subtitle ?? ''}
          />
        ))}
      </Stack>
    </>
  )
}

interface IBlogPostCardProps {
  slug: string
  title: string
  subtitle: string
}

// TODO: do we want to show published and updated date here?
const BlogPostCard: FC<IBlogPostCardProps> = ({ slug, title, subtitle }) => {
  const theme = useTheme()
  const { t } = useI18n()

  return (
    <Link
      to={`/blog/${slug}`}
      css={{
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        width: '100%',
        color: 'var(--color)',
        transition: ' color 0.2s, background 0.2s',
        background: 'var(--blog-card-background)',
        padding: theme.space('s', 'm'),
        borderRadius: '8px',
        '&:hover': {
          background: 'var(--blog-card-background--hover)',
        },
        '&:focus': {
          background: 'var(--blog-card-background--hover)',
        },
      }}
    >
      <Text
        element="h3"
        css={{
          fontSize: theme.fontSizes[5],
        }}
      >
        {title}
      </Text>
      {!!subtitle && (
        <Text
          element="h4"
          css={{
            fontSize: theme.fontSizes[4],
            color: 'var(--post-minor-text)',
            transition: ' color 0.5s',
          }}
        >
          {subtitle}
        </Text>
      )}
      <Text
        css={{
          marginTop: theme.space('xs'),
          color: 'var(--blog-card-link)',
          transition: ' color 0.2s',
          'a:hover > &': {
            color: 'var(--blog-card-link--hover)',
          },
          'a:focus > &': {
            color: 'var(--blog-card-link--hover)',
          },
        }}
      >
        {t('blog:read_more')}
      </Text>
    </Link>
  )
}
